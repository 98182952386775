import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

interface Option {
  value: string
  text: string
  type: string
  image_url: string
}

type EscapeFunction = (input: string) => string

const DEFAULT_PAGE = 0
const LIMIT = 30
const DEFAULT_LOAD_DELAY = 500
const MAX_OPTIONS = 200

export default class extends Controller {
  static values = { baseUrl: String }
  static targets = ['contentSelect']

  baseUrlValue!: string
  contentSelectTarget!: HTMLSelectElement
  private page = DEFAULT_PAGE
  private tomSelect!: TomSelect

  connect = (): void => {
    this.tomSelect = new TomSelect(
      this.contentSelectTarget as HTMLSelectElement,
      {
        plugins: ['virtual_scroll'],
        maxOptions: MAX_OPTIONS,
        preload: true,
        valueField: 'value',
        labelField: 'text',
        searchField: ['text'],
        firstUrl: this.getLoadMoreURL,
        load: this.handleLoad,
        onBlur: this.handleOnBlur,
        render: {
          option: (data: Option, escape: EscapeFunction) =>
            this.renderOption(data, escape),
          item: (data: Option, escape: EscapeFunction) =>
            this.renderItem(data, escape),
        },
        onItemRemove: this.handleItemRemove,
      }
    )
  }

  reload = (event: Event): void => {
    const target = event.currentTarget as HTMLElement
    if (target.dataset.url) {
      this.baseUrlValue = target.dataset.url
      this.clear()
    }
  }

  private handleLoad = async (
    query: string,
    callback: (options: Option[] | string) => void
  ): Promise<void> => {
    await this.delay()
    const url = query ? this.getSearchURL(query) : this.getLoadMoreURL()

    try {
      const options = await this.fetchOptions(url)
      this.updateOffset(query, options)
      callback(options)
    } catch (error) {
      callback(`Failed to load: ${error}`)
    }
  }

  private delay = (): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, DEFAULT_LOAD_DELAY))
  }

  private fetchOptions = async (url: string): Promise<Option[]> => {
    const response = await fetch(url)
    const data = await response.json()

    return data.map(this.transformOption)
  }

  private updateOffset = async (
    query: string,
    options: Option[]
  ): Promise<void> => {
    if (query === '' && options.length >= LIMIT) {
      this.page += 1
      this.tomSelect.setNextUrl(query, this.getLoadMoreURL())
    }
  }

  private renderOption = (data: Option, escape: EscapeFunction): string => {
    return `
      <div style="display: flex; align-items: center; text-align: left;">
        <div style="width:3rem;">
          <img src=${escape(data.image_url)} aria-hidden="true" />
        </div>
        <div>
          <div style="margin:1rem;">
            <span>
              ${escape(data.text)}
            </span>
            <div style="color: #888888;">${escape(data.type)}</div>
          </div>
        </div>
      </div>`
  }

  private renderItem = (data: Option, escape: EscapeFunction): string => {
    return `<span>${escape(data.text)}</span>`
  }

  private handleItemRemove = (): void => {
    if (!this.tomSelect.items) {
      this.clear()
    }
  }

  private handleOnBlur = (): void => {
    if (this.tomSelect.items.length == 0) {
      this.clear()
    }
  }

  private getLoadMoreURL = (): string => {
    return this.constructURL({ page: this.page.toString() })
  }

  private getSearchURL = (query: string): string => {
    return this.constructURL({ query })
  }

  private clear = (): void => {
    this.resetPagination()
    this.tomSelect.load('')
    this.tomSelect.clearOptions()
  }

  private resetPagination = (): void => {
    this.page = DEFAULT_PAGE
  }

  private transformOption = (data: Record<string, string>): Option => {
    return {
      value: data['sgid'],
      text: data['name'],
      type: data['friendly_name'],
      image_url: data['image_url'],
    }
  }

  private constructURL = (queryParams: Record<string, string>): string => {
    const url = new URL(this.baseUrlValue, window.location.origin)

    Object.keys(queryParams).forEach((key) => {
      url.searchParams.append(key, queryParams[key])
    })

    return url.toString()
  }
}
